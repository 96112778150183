import React, {useEffect, useState} from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Table } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
const API_HOST = "https://app.upcnsfe.com/publico/secret/vot";
const INVENTORY_API_URL = `${API_HOST}`;

function App() {
    const [data, setData] = useState([]);

    // GET request function to your Mock API
    const fetchInventory = () => {
        fetch(`${INVENTORY_API_URL}`)
            .then(res => res.json())
            .then(json => setData(json));
    }
    
    // Calling the function on component mount
    useEffect(() => {
        fetchInventory();
    }, []);

    var afavor = [];
    var encontra = [];
    for (var i = 0, max = data.length; i < max; i += 1) {
    
        afavor.push(data[i].total_favor);
        encontra.push(data[i].total_encontra);
    
    }
    function reducirnum(ingresar){
        if (isNaN(ingresar)===true){
            return 0;
        } else{
            return +ingresar;
        }
        
    }
    var colores = 'white';
    var totala = reducirnum(afavor[0]) + reducirnum(afavor[1])+reducirnum(afavor[2])+reducirnum(afavor[3])+reducirnum(afavor[4])+reducirnum(afavor[5])+reducirnum(afavor[6])+reducirnum(afavor[7])+reducirnum(afavor[8])+reducirnum(afavor[9])+reducirnum(afavor[10])+reducirnum(afavor[11])+reducirnum(afavor[12])+reducirnum(afavor[13])+reducirnum(afavor[14])+reducirnum(afavor[15])+reducirnum(afavor[16])+reducirnum(afavor[17])+reducirnum(afavor[18])
    var totalb = reducirnum(encontra[0])+reducirnum(encontra[1])+reducirnum(encontra[2])+reducirnum(encontra[3])+reducirnum(encontra[4])+reducirnum(encontra[5])+reducirnum(encontra[6])+reducirnum(encontra[7])+reducirnum(encontra[8])+reducirnum(encontra[9])+reducirnum(encontra[10])+reducirnum(encontra[11])+reducirnum(encontra[12])+reducirnum(encontra[13])+reducirnum(encontra[14])+reducirnum(encontra[15])+reducirnum(encontra[16])+reducirnum(encontra[17])+reducirnum(encontra[18])
    var total = totala + totalb
    var porcea = (totala * 100)/ total
    var porceb = (totalb * 100)/ total
    return (
        <div className="container">
            <h1>VOTACION PROPUESTA SALARIAL 2024</h1>
            <h2>del 9 de Octubre 2024 12hs. al 10 de Octubre 2024 12hs</h2>
            <Table striped bordered hover size="sm">
                <thead>
                <tr>
                    <th>HABILITADOS PARA VOTAR</th>
                    <th>-</th>
                </tr>
                <tr>
                    <th>VOTOS EMITIDOS</th>
                    <th>{ total}</th>
                    <th>100%</th>
                </tr>
                <tr>
                    <th>VOTOS A FAVOR</th>
                    <th>{totala}</th>
                    <th>{Math.round(porcea)}%</th>
                </tr>
                <tr>
                    <th>VOTOS EN CONTRA</th>
                    <th>{totalb}</th>
                    <th>{Math.round(porceb)}%</th>
                </tr>
                <tr>
                    <th>
                    </th>
                </tr>
                <tr>
                    <th>Departamento</th>
                    <th>Votos a favor</th>
                    <th>Votos en contra</th>
                    <th> % Votos a favor</th>
                    <th> % Votos en contra</th>
                    
                </tr>
                </thead>
                <tbody>
                    {
                        data.map((item) => (
                            <tr key={item.dpto}>
                                <style>backgroundColor = { item.total_favor > item.total_encontra ? colores='#2691B6'  : colores='#D03402'}
                                </style>
                                <td style= {{backgroundColor: colores}}>{item.dpto == '1' ? 'LA CAPITAL' : item.dpto == '2' ? 'BELGRANO' : item.dpto == '3' ? 'CASEROS' : item.dpto == '4' ? 'CASTELLANOS' : item.dpto == '5' ? 'CONSTITUCION' : item.dpto == '6' ? 'GARAY' : item.dpto == '7' ? 'GENERAL LOPEZ' : item.dpto == '8' ? 'GENERAL OBLIGADO' : item.dpto == '9' ? 'IRIONDO' : item.dpto == '10' ? 'LAS COLONIAS' : item.dpto == '11' ? '9 DE JULIO' : item.dpto == '12' ? 'ROSARIO' : item.dpto == '13' ? 'SAN CRISTOBAL' : item.dpto == '14' ? 'SAN JAVIER' : item.dpto == '15' ? 'SAN JERONIMO' : item.dpto == '16' ? 'SAN JUSTO' : item.dpto == '17' ? 'SAN LORENZO' : item.dpto == '18' ? 'SAN MARTIN' : item.dpto == '19' ? 'VERA' : item.dpto } </td>
                                <td >{item.total_favor || 0 }</td>
                                <td>{item.total_encontra || 0 }</td>
                                <td>{Math.round((item.total_favor*100)/(item.total_favor+item.total_encontra)) || 0}%</td>
                                <td>{Math.round((item.total_encontra*100)/(item.total_favor+item.total_encontra)) || 0}%</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    );
}

export default App;
